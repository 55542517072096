import { lazy, FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_transparency/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_transparency/assets/ts/_utils'
import { WithChildren } from '../../_transparency/helpers'
import UrlRegistration from '../pages/urlRegistration/UrlRegistration'
import UrlMapping from '../pages/urlMapping/UrlMapping'
import ManageRoutes from '../pages/manageRoutes/ManageRoutes'
import { UccComplaintsWrapper } from '../pages/uccComplaints/UccComplaints'
import { SMSPackWrapper } from '../pages/smsPack/SMSPack'
import { OrdersListWrapper } from '../pages/ordersList/OrdersList'
import { SupportCenterWrapper } from '../pages/supportCenter/Support'
import { MyProfileWrapper } from '../pages/myProfile/MyProfile'
import { InvoiceWrapper } from '../modules/apps/orderslist/Invoice'
import Payment from '../pages/Payment/Payment'
import PaymentLayout from '../modules/payment/PaymentLayout'
import VendorListWrapper from '../pages/VendorList/VendorListWrapper'
import { useLayout } from '../../_transparency/layout/core'
import { axiosClientAuth } from '../utils'
import MyIP from '../pages/myIPs/MyIP'
import BulkReport from '../pages/BulkReport/BulkReport'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const { setLayoutType, setIsRegistred } = useLayout();

  useEffect(() => {
    axiosClientAuth
      .get('/auth/user-profile')
      .then((res) => {
        setIsRegistred(res.data?.data?.is_activated);
      })
      .catch((err) => console.log(err))
  }, []);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='urlregistration' element={<UrlRegistration />} />
        <Route path='urlmapping' element={<UrlMapping />} />
        <Route path='myip' element={<MyIP />} />
        <Route path='vendorlist' element={<VendorListWrapper />} />
        <Route path='manageroutes' element={<ManageRoutes />} />
        <Route path='ucccomplaints' element={<UccComplaintsWrapper />} />
        {/* <Route path='smspack' element={<SMSPackWrapper />} /> */}
        <Route path='orderslist' element={<OrdersListWrapper />} />
        <Route path='support' element={<SupportCenterWrapper />} />
        <Route path='myprofile' element={<MyProfileWrapper />} />
        <Route path='invoice' element={<InvoiceWrapper />} />
        <Route path='bulk-report' element={<BulkReport />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>

      <Route element={<PaymentLayout />}>
        <Route path='payment' element={<Payment />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }