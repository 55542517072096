/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_transparency/helpers'
import {PageLink, PageTitle, useLayout} from '../../../_transparency/layout/core'
import {TablesWidget1} from '../../../_transparency/partials/widgets'
import {ChartsWidgetSMSStats} from '../../../_transparency/partials/widgets/_new/charts/ChartsWidgetSMSStats'
import {ListsWidgetApprovedURL} from '../../../_transparency/partials/widgets/_new/lists/ListsWidgetApprovedURL'
import {EngageWidgetDashboard} from '../../../_transparency/partials/widgets/_new/engage/EngageWidgetDashboard'
import {TablesWidgetScrubbingLogs} from '../../../_transparency/partials/widgets/_new/tables/TablesWidgetScrubbingLogs'
import {axiosClientAuth} from '../../utils'
import {CardsWidgetPlanDetails} from '../../../_transparency/partials/widgets/_new/cards/CardsWidgetPlanSummary'
import {CardsWidgetUrlSummary} from '../../../_transparency/partials/widgets/_new/cards/CardsWidgetUrlSummary'
import {MixedWidgetIpStats} from '../../../_transparency/partials/widgets/_new/mixed/MixedWidgetIpStats'
import TableWidgetUrlStats from '../../../_transparency/partials/widgets/_new/tables/TableWidgetUrlStats'
import CardsDashboardMain from '../../../_transparency/partials/widgets/_new/cards/CardsDashboardMain'

const DashboardPage: FC = () => {
  const [data, setData] = useState({
    ip_blacklist_count: {
      blacklisted: 0,
      whitelisted: 0,
    },
    url_blacklist_count: {
      blacklisted: 0,
      whitelisted: 0,
      pending: 0,
      total: 0,
    },
    approved_urls: [],
    vendor_count: 0,
    ip_per_month: [0, 0, 0, 0, 0, 0],
  })
  const {setLayoutType, setIsRegistred, dashboardDateFilter} = useLayout()

  useEffect(() => {
    if (dashboardDateFilter?.length > 0) {
      axiosClientAuth
        .get(
          `/main/pe-dashboard?date_from=${dashboardDateFilter[0]}&date_to=${dashboardDateFilter[1]}`
        )
        .then((res) => {
          setData(res.data.data)
        })
        .catch((err) => console.log(err))
    } else {
      axiosClientAuth
        .get(`/main/pe-dashboard`)
        .then((res) => {
          setData(res.data.data)
        })
        .catch((err) => console.log(err))
    }
  }, [dashboardDateFilter])

  useEffect(() => {
    setLayoutType('dark-header')
  }, [])

  const [profile, setProfile] = useState<any>()

  useEffect(() => {
    axiosClientAuth
      .get('/auth/user-profile')
      .then((res) => {
        console.log(res)
        setProfile(res.data.data)
        setIsRegistred(res.data?.data?.is_activated)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      <div className='row g-10 mb-10'>
        <div className='col-12 col-lg-7'>
          <div className='row gx-10 h-100'>
            <div className='col-12 col-lg-5 mb-10'>
              {/* <CardsWidgetPlanDetails
                className='h-100'
                description='Plan Details'
                plan={profile?.user_subscriptions}
                totalsms={totalsms}
                usedsms={usedsms}
                color='#F1416C'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              /> */}
              <CardsDashboardMain data={data} className='h-100' />
            </div>
            <div className='col-12 col-lg-7 mb-10'>
              <CardsWidgetUrlSummary className='h-100' data={data.url_blacklist_count} />
            </div>
            <div className='col-12'>
              <EngageWidgetDashboard className='h-100 py-5' />
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-5'>
          <TablesWidget1 className='h-100' />
        </div>
      </div>

      <div className='row g-10 mb-10'>
        <div className='col-12 col-lg-4'>
          <MixedWidgetIpStats className='' chartColor='info' chartHeight='200px' data={data} />
        </div>
        <div className='col-12 col-lg-8'>
          <ChartsWidgetSMSStats className='h-100' />
        </div>
      </div>

      <div className='row g-10'>
        <div className='col-12'>
          <TableWidgetUrlStats data={data.approved_urls} />
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('dashboard')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>

      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
