import React from 'react'
import {DatePicker} from 'antd'
import {useLayout} from '../../../_transparency/layout/core'

const DashboardRightToolbar = () => {
  const {RangePicker} = DatePicker

  const {setDashboardDateFilter} = useLayout()

  return (
    <>
      <div className='d-flex align-items-center'>
        <RangePicker
          className='bg-light text-white border-0 shadow-none me-2'
          style={{height: '40px'}}
          onChange={(value, dateString) => setDashboardDateFilter(dateString)}
        />
      </div>
    </>
  )
}

export default DashboardRightToolbar
