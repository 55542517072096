/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {axiosClient, axiosClientAuth} from '../../../../app/utils'

type Props = {
  className: string
}

const operatorData = [
  {
    name: 'Airtel',
    imgSrc: '/media/operator/airtel.svg',
    smsSent: '30%',
    color: 'primary',
  },
  {
    name: 'Vodafone Idea',
    imgSrc: '/media/operator/vi.svg',
    smsSent: '50%',
    color: 'secondary',
  },
  {
    name: 'BSNL',
    imgSrc: '/media/operator/bsnl.png',
    smsSent: '89%',
    color: 'success',
  },
  {
    name: 'Jio',
    imgSrc: '/media/operator/jio.png',
    smsSent: '23%',
    color: 'danger',
  },
  {
    name: 'MTNL',
    imgSrc: '/media/operator/mtnl.svg',
    smsSent: '63%',
    color: 'info',
  },
  {
    name: 'Reliance',
    imgSrc: '/media/operator/reliance.svg',
    smsSent: '34%',
    color: 'danger',
  },
  {
    name: 'Tata Teleservice',
    imgSrc: '/media/operator/tata.png',
    smsSent: '2%',
    color: 'warning',
  },
  {
    name: 'QTL',
    imgSrc: '/media/operator/hfcl.webp',
    smsSent: '47%',
    color: 'dark',
  },
]

const TablesWidget1: React.FC<Props> = ({className}) => {
  const [operators, setOperators] = useState<any>([])

  useEffect(() => {
    axiosClientAuth
      .get(`auth/get-operators`)
      .then((res) => {
        setOperators(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const onClickLogin = (op) => {
    const tmpFind = operators.filter((ele) => ele.name == op.name)
    if (tmpFind?.length > 0) {
      const mData = {
        operator_uuid: tmpFind[0].uuid,
      }
      axiosClientAuth
        .post('/auth/get-pe-cross-auth-token', mData)
        .then((response) => {
          const {cross_auth_token, pe_id} = response?.data?.data
          const mData2 = {
            cross_auth_token,
            pe_id,
          }
          axiosClient
            .post(response?.data?.data?.redirect_url, mData2, {headers: {Authorization: ''}})
            .then((response1) => {
              window.open(
                response1?.data?.data?.redirect_url + response1?.data?.data?.access_token,
                '_blank'
              )
            })
            .catch((error) => {
              console.error(error)
            })
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Operators List</span>
          <span className='text-muted fw-semibold fs-7'>Total 8 Operator</span>
        </h3>
      </div>
      <div className='card-body pt-5'>
        {operatorData?.map((op, index) => {
          return (
            <>
              <div className='d-flex flex-stack'>
                <div className='d-flex align-items-center me-3'>
                  <img src={toAbsoluteUrl(op.imgSrc)} className='me-4 w-30px' alt='' />
                  <div className='flex-grow-1'>
                    <a
                      href='/'
                      onClick={(e) => {
                        e.preventDefault()
                        onClickLogin(op)
                      }}
                      className='text-gray-800 text-hover-primary fs-5 fw-bold lh-0'
                    >
                      {op.name}
                    </a>
                    <span className='text-gray-400 fw-semibold d-block fs-6'>100 sms sent</span>
                  </div>
                </div>
                <div className='d-flex align-items-center w-100 mw-125px'>
                  <div className={`progress h-6px w-100 me-2 bg-light-${op.color}`}>
                    <div
                      className={`progress-bar bg-${op.color}`}
                      role='progressbar'
                      style={{width: op.smsSent}}
                      aria-valuenow={65}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <span className='text-gray-400 fw-semibold'>{op.smsSent}</span>
                </div>
              </div>
              {index != operatorData.length - 1 && (
                <div className='separator separator-dashed my-3' />
              )}
            </>
          )
        })}
      </div>
    </div>
  )
}

export {TablesWidget1}
