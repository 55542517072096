/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_transparency/helpers'
import { PageLink, PageTitle, useLayout } from '../../../_transparency/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ListsWidgetHighlight
} from '../../../_transparency/partials/widgets'
import { ChartsWidgetSMSStats } from '../../../_transparency/partials/widgets/_new/charts/ChartsWidgetSMSStats'
import { ListsWidgetApprovedURL } from '../../../_transparency/partials/widgets/_new/lists/ListsWidgetApprovedURL'
import { ListsWidgetOperator } from '../../../_transparency/partials/widgets/_new/lists/ListsWidgetOperator'
import { CardsWidgetComplaintsResolved } from '../../../_transparency/partials/widgets/_new/cards/CardsWidgetComplaintsResolved'
import { ListsWidgetExistingLogs } from '../../../_transparency/partials/widgets/_new/lists/ListsWidgetExistingLogs'
import { ListsWidgetOperatorList } from '../../../_transparency/partials/widgets/_new/lists/ListsWidgetOperatorLists'
import { ListsWidgetHeader } from '../../../_transparency/partials/widgets/_new/lists/ListsWidgetHeaders'
import { ChartsWidgetUCCComplaintstats } from '../../../_transparency/partials/widgets/_new/charts/ChartsWidgetUCCComplaintsStats'
import { TablesWidgetComplaintsLogs } from '../../../_transparency/partials/widgets/_new/tables/TablesWidgetComplaintsLogs'
import { axiosClientAuth } from '../../utils'

const DashboardPage: FC = () => {
  const [data, setData] = useState({
    "ucc_logs": [],
    "status_counts": {
      "total_ucc": 0,
      "resolved_ucc": 0,
      "open_ucc": 40
    },
    "ucc_headers": []
  })
  const getData = () => {
    axiosClientAuth.get("/main/get-ucclogs").then(resp => {
      setData(resp.data.data)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidgetComplaintsResolved
            className='h-md-50 mb-5 mb-xl-10'
            description='Total Resolved Complaints'
            color='#F1416C'
            data={data?.status_counts}
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <ListsWidgetExistingLogs className='h-md-50 mb-5 mb-xl-10' />
        </div>

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <ListsWidgetOperatorList className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidgetHeader className='h-lg-50' data={data?.ucc_headers} />
        </div>

        <div className='col-xxl-6'>
          <ChartsWidgetUCCComplaintstats className='h-100 mb-xl-8' />
        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <TablesWidgetComplaintsLogs data={data?.ucc_logs} className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const UccComplaintsWrapper: FC = () => {
  const intl = useIntl()
  const { setRightTolbar } = useLayout()

  useEffect(() => {
    setRightTolbar('ucccomplaints')
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>{intl.formatMessage({ id: 'UCCCOMPLAINTS' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { UccComplaintsWrapper }