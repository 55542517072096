/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  className: string,
  data?: any
}

const ListsWidgetHeader = ({ className, data }: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>Headers Lists</h3>
      <span className='text-gray-400 fw-semibold fs-6'>{data?.length} Operators</span>
      {/* <div className='card-toolbar'></div> */}
    </div>

    <div className='card-body pt-2 pb-4 h-100'>
      <div className='d-flex flex-column flex-row-fluid h-75' style={{ overflowY: 'scroll' }}>
        {
          data?.map((ele, i) => (
            <div key={i} className='d-flex fw-semibold align-items-center mt-3'>
              <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
              <div className='text-gray-500 flex-grow-1 me-4'>{ele.header}</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>{ele.count}</div>
            </div>
          ))
        }
        {/* <div className='d-flex fw-semibold align-items-center'>
          <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
          <div className='text-gray-500 flex-grow-1 me-4'>SWIGGY</div>
          <div className='fw-bolder text-gray-700 text-xxl-end'>30</div>
        </div>
        <div className='d-flex fw-semibold align-items-center'>
          <div className='bullet w-8px h-3px rounded-2 bg-gray me-3'></div>
          <div className='text-gray-500 flex-grow-1 me-4'>AMEEAG</div>
          <div className='fw-bolder text-gray-700 text-xxl-end'>30</div>
        </div>
        <div className='d-flex fw-semibold align-items-center'>
          <div className='bullet w-8px h-3px rounded-2 bg-gray me-3'></div>
          <div className='text-gray-500 flex-grow-1 me-4'>SWGBAL</div>
          <div className='fw-bolder text-gray-700 text-xxl-end'>30</div>
        </div>
        <div className='d-flex fw-semibold align-items-center'>
          <div className='bullet w-8px h-3px rounded-2 bg-gray me-3'></div>
          <div className='text-gray-500 flex-grow-1 me-4'>LOUPHI</div>
          <div className='fw-bolder text-gray-700 text-xxl-end'>30</div>
        </div> */}
      </div>
    </div>
  </div>
)

export { ListsWidgetHeader }
