import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_transparency/helpers'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { axiosClientAuth } from '../../utils'
import { DatePicker } from 'antd'
import { useLayout } from '../../../_transparency/layout/core'
import RegistrationModal from '../../modules/registration/RegistrationModal'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Route Name is required'),
})

const initialValues: {
  name: string
  ips: string[],
  vendors: string[],
  initiator: string,
  operator: string,
} = {
  initiator: '',
  operator: '',
  name: '',
  ips: [],
  vendors: [],
}

const ManageRoutesRightToolbar = () => {
  const [loading, setLoading] = useState(false)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [vendors, setVendors] = useState<any>([])
  const [ips, setIps] = useState({})
  const [profile, setProfile] = useState<any>();

  useEffect(() => {
    axiosClientAuth
      .get('/main/nodes')
      .then((resp) => {
        setVendors(resp.data.data);
      })
      .catch((error) => console.log(error))
    axiosClientAuth
      .get('/auth/user-profile')
      .then((res) => {
        setProfile(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.vendors.length > 0) {
          if (values.vendors.filter(v => v == '').length > 0) {
            Swal.fire({
              titleText: 'Error!',
              text: 'All Vendors are Required !!!',
              icon: 'error',
              confirmButtonText: 'Ok! Got It',
              timer: 2000,
              timerProgressBar: true,
            })
            return;
          }
          if (!values.operator) {
            Swal.fire({
              titleText: 'Error!',
              text: 'Operator Vendor is Required !!!',
              icon: 'error',
              confirmButtonText: 'Ok! Got It',
              timer: 2000,
              timerProgressBar: true,
            })
            return;
          }
          let inputData = {
            name: values.name,
            nodes: JSON.parse(JSON.stringify(values.vendors))
          }
          inputData.nodes.push(values.operator);
          const res = await axiosClientAuth.post('/main/routes', inputData)
          setLoading(false)
          Swal.fire({
            titleText: 'Success!',
            text: 'Route Created Successfully.',
            icon: 'success',
            confirmButtonText: 'Ok! Got It',
          }).then((isConfirm) => {
            if (isConfirm) {
              window.location.reload()
            }
          })
        } else {
          Swal.fire({
            titleText: 'Error!',
            text: 'Minimum 1 Vendor is Required !!!',
            icon: 'error',
            confirmButtonText: 'Ok! Got It',
            timer: 2000,
            timerProgressBar: true,
          })
        }
      } catch (error) {
        console.log(values);
        console.error(error)
        // setLoading(false)
      }
    },
  })

  const onChangeHandler = (e: any) => {
    if (e.target.value) {
      formik.setValues((prev) => ({
        ...prev,
        vendors: [...Array(parseInt(e.target.value))].map(() => ''),
      }))
    } else {
      formik.setValues((prev) => ({ ...prev, vendors: [] }))
    }
  }

  const onChangeHandlerVendor = (e: any, index: any) => {
    if (e.target.value) {
      let mTemp = formik.values.vendors
      mTemp[index] = e.target.value

      formik.setValues((prev: any) => ({
        ...prev,
        vendors: mTemp,
      }))
    }
  }

  const onChangeHandlerIp = (e: any, index: any) => {
    if (e.target.value) {
      let mTemp = formik.values.ips;
      if (mTemp.length <= index) {
        mTemp.push(e.target.value);
      } else {
        mTemp[index] = e.target.value
      }

      formik.setValues((prev: any) => ({
        ...prev,
        ips: mTemp,
      }))
    }
  }

  const { RangePicker } = DatePicker;

  const { isRegistred, setManageRouteDateFilter } = useLayout();

  console.log(formik.values)

  return (
    <>
      <div className='d-flex align-items-center'>
        <RangePicker
          className='bg-light text-white border-0 shadow-none'
          style={{ height: '40px' }}
          onChange={(value, dateString) => setManageRouteDateFilter(dateString)}
        />

        <button
          className='btn btn-sm fw-bold btn-primary ms-3'
          style={{ height: '40px' }}
          onClick={handleShow}
        >
          Add Route
        </button>
      </div>

      {isRegistred
        ? <Modal
          className='modal-sticky modal-ld modal-sticky-lg modal-sticky-bottom-right'
          show={show}
          animation={true}
          centered={true}
        >
          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='d-flex justify-content-end'>
              <button
                className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
                onClick={handleClose}
              >
                <KTIcon iconName='cross' className='text-gray fs-1' />
              </button>
            </div>

            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
              <div className='mb-13 text-center'>
                <h1 className='mb-3'>Add Routes</h1>
                <div className='text-muted fw-semibold fs-5'>
                  If you need more info, please check{' '}
                  <a href='#' className='fw-bold link-primary'>
                    Our Guidelines
                  </a>
                  .
                </div>
              </div>

              {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              ) : null}

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Route Name</label>
                <input
                  placeholder='Enter Route Name'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Initiator IP</label>
                <input
                  className={clsx(
                    'form-control bg-transparent'
                  )}
                  placeholder='Enter Initiator IP'
                  value={profile?.name}
                  disabled={true}
                />
              </div>

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>No. Of Vendor</label>
                <select
                  className='form-select form-select-sm form-select-solid'
                  onChange={onChangeHandler}
                >
                  <option value=''>Select Vendor</option>
                  {
                    Array.from({ length: vendors?.vendors?.length }, (_, i) => i + 1)?.map(ele => (
                      <option value={ele}>{ele}</option>
                    ))
                  }
                </select>
              </div>

              {formik.values.vendors.map((item: any, index) => (
                <div className='row mb-8'>
                  <div className='col-md-12'>
                    <select
                      className='form-select form-select-sm form-select-solid'
                      onChange={(e) => onChangeHandlerVendor(e, index)}
                      value={item}
                    >
                      <option value=''>Select Vendor</option>
                      {vendors?.vendors?.filter((ele) => formik.values?.vendors.findIndex((item: any, fIndex) => (item == ele.uuid && fIndex != index)) == -1)?.map((ele: any) => (
                        <option value={ele.uuid}>{ele.name}</option>
                      ))
                      }
                    </select>
                  </div>
                  {/* <div className='col-md-6'>
                    <select
                      className='form-select form-select-sm form-select-solid'
                      onChange={(e) => onChangeHandlerIp(e, index)}
                      value={formik.values.ips[index]}
                    >
                      <option value=''>Select IP</option>
                      {ips?.[index]
                        ?.filter((ip: any) =>
                          formik.values.ips.indexOf(ip.uuid) == index ||
                          formik.values.ips.indexOf(ip.uuid) == -1
                        )
                        ?.map((ele: any) => (
                          <option value={ele.uuid}>{ele.address}</option>
                        ))
                      }
                    </select>
                  </div> */}
                </div>
              ))}

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Operator IP</label>
                {/* <input
                  placeholder='Enter Route Name'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='text'
                  name='name'
                  autoComplete='off'
                /> */}
                <select
                  {...formik.getFieldProps('operator')}
                  className={clsx(
                    'form-select form-select-sm form-select-solid')}
                  name='operator'
                  autoComplete='off'
                >
                  <option value=''>Select Operator</option>
                  {
                    vendors?.operators?.map(item => (
                      <option value={item.uuid}>{item.name == "QTL" ? "VMIPL" : item.name}</option>
                    ))
                  }
                </select>

                {formik.touched.operator && formik.errors.operator && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.operator}</span>
                  </div>
                )}
              </div>

              {/* begin::Action */}
              <div className='text-center mb-10'>
                <button type='reset' className='btn btn-light me-3' onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}>
                  <span className='indicator-label'>Cancel</span>
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal>
        : <RegistrationModal show={show} handleClose={handleClose} />
      }
    </>
  )
}

export default ManageRoutesRightToolbar