/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../../assets/ts/_utils'
import { useThemeMode } from '../../../layout/theme-mode/ThemeModeProvider'
import { DatePicker } from 'antd'
import { axiosClientAuth } from '../../../../../app/utils'

type Props = {
  className: string
}

const ChartsWidgetSMSStats: React.FC<Props> = ({ className }) => {
  const [smsStats, setSmsStats] = useState({
    total: 0,
    successful: 0,
    unsuccessful: 0,
    chart: {
      delta: [],
      success: [],
      failed: []
    }
  });
  const [dateFilter, setDateFilter] = useState<any>(null);

  useEffect(() => {
    if (dateFilter?.length > 0 && dateFilter[0] != '') {
      axiosClientAuth
        .get(`/main/get-message-analytics?date_from=${dateFilter[0]}&date_to=${dateFilter[1]}`)
        .then(res => {
          if (res.data.status) {
            setSmsStats(res.data.data)
          }
        })
        .catch(err => console.log(err))
    } else {
      axiosClientAuth
        .get('/main/get-message-analytics')
        .then(res => {
          console.log(res)
          if (res.data.status) {
            setSmsStats(res.data.data)
          }
        })
        .catch(err => console.log(err))
    }
  }, [dateFilter])

  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, smsStats]);

  function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-info')
    const lightColor = getCSSVariableValue('--bs-info-light')

    return {
      series: [
        {
          name: 'Failed',
          data: smsStats.chart.failed,
        },
        {
          name: 'Success',
          data: smsStats.chart.success,
        }
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      // colors:[getCSSVariableValue('--bs-danger'), getCSSVariableValue('--bs-success')],
      // fill: {
      // type: 'solid',
      // opacity: 1,
      // },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 2,
        // colors: [baseColor],
      },
      xaxis: {
        categories: smsStats.chart.delta,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return val + ' SMS'
          },
        },
      },
      // colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      // markers: {
      //   strokeColors: baseColor,
      //   strokeWidth: 3,
      // },
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>SMS Stats</span>

          <span className='text-muted fw-semibold fs-7'>{smsStats.total} Messages sent</span>
        </h3>

        <div className='card-toolbar' data-kt-buttons='true'>
          {/* <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_3_year_btn'
          >
            Year
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_3_month_btn'
          >
            Month
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
            id='kt_charts_widget_3_week_btn'
          >
            Week
          </a> */}
          <DatePicker.RangePicker
            className='bg-light text-white border-0 shadow-none'
            style={{ height: '40px' }}
            onChange={(value, dateString) => setDateFilter(dateString)}
          />
        </div>
      </div>

      <div className='card-body p-4'>
        <div className='d-flex px-5'>
          <div className='flex-grow-1'>
            <div className='d-flex fw-semibold align-items-center'>
              <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
              <div className='text-gray-500 me-4'>Total</div>
              <div className='h4 m-0 fw-bolder text-gray-700 text-xxl-end'>{smsStats?.total}</div>
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex fw-semibold align-items-center'>
              <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
              <div className='text-gray-500 me-4'>Success</div>
              <div className='h4 m-0 fw-bolder text-gray-700 text-xxl-end'>{smsStats?.successful}</div>
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex fw-semibold align-items-center'>
              <div className='bullet w-8px h-3px rounded-2 bg-danger me-3'></div>
              <div className='text-gray-500 me-4'>Failed</div>
              <div className='h4 m-0 fw-bolder text-gray-700 text-xxl-end'>{smsStats?.unsuccessful}</div>
            </div>
          </div>
        </div>

        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '360px' }}></div>
      </div>
    </div>
  )
}

export { ChartsWidgetSMSStats }