/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  className: string
}

const ListsWidgetOperatorList = ({ className }: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>Operators Lists</h3>
      <span className='text-gray-400 fw-semibold fs-6'>3 Operators</span>
      {/* <div className='card-toolbar'></div> */}
    </div>

    <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
      <div className='d-flex flex-column content-justify-center flex-row-fluid'>
        <div className='d-flex fw-semibold align-items-center mb-3'>
          <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
          <div className='text-gray-500 flex-grow-1 me-4'>Airtel</div>
          <div className='fw-bolder text-gray-700 text-xxl-end'>200</div>
        </div>
        <div className='d-flex fw-semibold align-items-center mb-3'>
          <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
          <div className='text-gray-500 flex-grow-1 me-4'>Jio</div>
          <div className='fw-bolder text-gray-700 text-xxl-end'>30</div>
        </div>
        <div className='d-flex fw-semibold align-items-center mb-3'>
          <div className='bullet w-8px h-3px rounded-2 bg-gray me-3'></div>
          <div className='text-gray-500 flex-grow-1 me-4'>Vodafone Idea</div>
          <div className='fw-bolder text-gray-700 text-xxl-end'>30</div>
        </div>
      </div>
    </div>
  </div>
)

export { ListsWidgetOperatorList }
